import Button from "./Button";

const Hero = () => {
  return (
    <>
      <div className="relative sm:h-[625px] h-96 bg-cover sm:bg-cover sm:bg-no-repeat bg-center bg-tripoli sm:w-full mx-auto">
        <div className=" md:p-8 md:py-48 max-sm:py-24">
          <div className="  text-4xl font-semibold max-sm:text-base max-sm:px-3 ">
            <p className=" text-white ">
              Immigrant Empowerment initiative in the North.
            </p>
            <span className="text-white">
              Bridging borders, Building futures.
            </span>
            <p className="text-white mt-2 font-bold md:text-5xl text-justify">
              YES WE CAN #2
            </p>
          </div>
        </div>
        {/* <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}></div> */}
      </div>
      <section className="justify-content-center max-container padding-container flex flex-col gap-20 py-10 pb-32 md:gap-28 lg:py-20 xl:flex-row">
        {/* <div className="hero-map"/> */}

        <div id="home" className="relative z-20 flex flex-1 flex-col xl:w-1/2">
          {/* <img 
          src="/camp.svg"
          alt="camp"
          width={50}
          height={50}
          className="absolute left-[-5px] top-[-30px] w-10 lg:w-[50px]"
        /> */}
          <h1 className="text-center bold-18 lg:text-3xl">
            International <span className="text-blue-500">Immigration</span>{" "}
            Conference
          </h1>
          <div className="w-full flex justify-center max-sm:regular-20 lg:regular-24 text-center mt-6 -mb-24 lg:-mb-0 text-gray-30">
            Beirut Arab University
            <br />
            CCIAT Chamber of Commerce Industry & Agriculture of Tripoli <br />
            HALFA Hommes d’Affaires Libanais de France <br />
            ANLCCI Australian New Zealand Lebanese Chamber of Commerce Int.
            Melbourne <br />
            LEC Lebanese Executives Council in KSA <br />
            Union of Arab Chambers <br />
            Have the pleasure to invite you to attend the Convention
          </div>
          {/* <div className="my-11 flex flex-wrap gap-5"></div> */}
        </div>

        {/* <div className="relative flex flex-1 items-start">
        <div className="relative z-20 flex w-[268px] flex-col gap-8 rounded-3xl bg-green-90 px-7 py-8">
          <div className="flex flex-col">
            <div className="flexBetween">
              <p className="regular-16 text-gray-20">Location</p>
            </div>
            <p className="bold-20 text-white">Tripoli</p>
          </div>

          <div className="flexBetween">
            <div className="flex flex-col">
              <p className="regular-16 block text-gray-20">Distance</p>
              <p className="bold-20 text-white">85.3 km</p>
            </div>
            <div className="flex flex-col">
              <p className="regular-16 block text-gray-20">Elevation</p>
              <p className="bold-20 text-white">21 m</p>
            </div>
          </div>
        </div>
      </div> */}
      </section>
    </>
  );
};

export default Hero;

import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  FEATURES,
  FEATURES2,
  FEATURES3,
  FEATURESONE,
  FEATURESTHREE,
  FEATURESTWO,
} from "../constants";

// const handleNextClick = () => {
//   if (currentContent < FEATURES.length - 1) {
//     setCurrentContent(currentContent + 1);
//   }
// };

const R = [
  {
    day: "Day 1",
    sessions: [
      { session: "State of Lebanon’s Labor Market", features: FEATURES },
      {
        session:
          "What is the Private Sector in Lebanon looking for? Labor Supply versus Demand in the Lebanese economy ",
        features: FEATURES2,
      },
      {
        session:
          "Pathways abroad: exploring challenges & opportunities for Lebanese youth graduates",
        features: FEATURES3,
      },
    ],
  },
  {
    day: "Day 2",
    sessions: [
      {
        session: "Contribution of women in the Lebanese economy",
        features: FEATURESONE,
      },
      {
        session:
          "Building a sustainable future for youth entrepreneurs & startups",
        features: FEATURESTWO,
      },
      {
        session:
          "Universities’ Role in Curriculum Development and Economic Development",
        features: FEATURESTHREE,
      },
    ],
  },
];

const Features = () => {
  const [index, setIndex] = useState(0);
  const [sessionIndex, setIndexSession] = useState(0);
  return (
    <div>
      <section
        id="speaker"
        className="flex-col flexCenter overflow-hidden bg-feature-bg bg-center bg-no-repeat py-24"
      >
        <div className="sm:flex sm:justify-end sm:w-[1850px] sm:-ml-96 sm:mb-8">
          <div className="lg:mr-36">
            <div className="bg-blue-500 mt-10 lg:-mt-0 p-4 lg:p-8 max-sm:m-14 xl:max-h-[200px] xl:rounded-5xl xl:py-8 relative overflow-hidden rounded-5xl">
              <h1 className="text-5xl capitalize text-white px-7 py-2 max-sm:text-base">
                <strong>OUR SPEAKERS</strong>
              </h1>
              <img
                src="/quote.svg"
                alt="camp-2"
                width={186}
                height={219}
                className="camp-quote"
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center sm:px-10">
          {/* back button */}
          <div className="h-[100%] max-sm:hidden p-2">
            <button
              className={`mt-4 border border-1  text-white p-3 rounded-full ${
                index === 0 ? "bg-gray-100" : ""
              }`}
              onClick={() => {
                if (sessionIndex === 0) {
                  setIndex(index - 1);
                  setIndexSession(2);
                  return;
                }
                setIndexSession(sessionIndex - 1);
              }}
              disabled={index === 0 && sessionIndex === 0}
            >
              <FaChevronLeft size={20} color="black" />
            </button>
          </div>
          <div className="lg:flex max-container padding-container relative w-fit justify-end shadow shadow-gray-20 sm:h-fit">
            <div className="flex justify-center items-center flex-col flex-1 lg:min-h-[900px] lg:pb-40 max-sm:w-auto max-sm:flex max-sm:justify-center">
              <h1 className="regular-32 bold-40 lg:pb-0">{R[index].day}</h1>
              <p className="text-xl text-blue-600 font-bold p-4 text-center max-sm:ml-14 w-full">
                {R[index].sessions[sessionIndex].session}
              </p>
            </div>
            <div className="z-20 flex w-full flex-col lg:w-[65%] p-14">
              {/* <div id='speaker' className='relative'>
            <h2 className="bold-40 lg:bold-64">Our Speakers</h2>
          </div> */}
              <ul className="mt-5 grid gap-10 md:grid-cols-1 w-full lg:grid-cols-2 lg:gap-20">
                {R[index].sessions[sessionIndex].features.map((feature) => (
                  <li
                    key={feature.title}
                    className={
                      R[index].sessions[sessionIndex].features.length === 1
                        ? "mx-auto"
                        : ""
                    }
                  >
                    <FeatureItem
                      title={feature.title}
                      icon={feature.icon}
                      description={feature.description}
                      topic={feature.topic}
                      speakers={feature.speakers}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* next button */}
          <div className="p-2">
            <button
              className={`mt-4 border border-1 text-white p-3 rounded-full max-sm:hidden ${
                index === R.length - 1 ? "bg-gray-100" : ""
              }`}
              onClick={() => {
                if (sessionIndex === 2) {
                  setIndex(index + 1);
                  setIndexSession(0);
                  return;
                }
                setIndexSession(sessionIndex + 1);
              }}
              disabled={index === R.length - 1 && sessionIndex === 2}
            >
              <FaChevronRight size={20} color="black" />
            </button>
          </div>
        </div>

        <div className="flex gap-6">
          {/* back button */}
          <button
            className={`mt-4 border border-1 text-white p-3 rounded-full sm:hidden  ${
              index === 0 ? "bg-gray-100" : ""
            }`}
            onClick={() => {
              if (sessionIndex === 0) {
                setIndex(index - 1);
                setIndexSession(2);
                return;
              }
              setIndexSession(sessionIndex - 1);
            }}
            disabled={index === 0 && sessionIndex === 0}
          >
            <FaChevronLeft size={20} color="black" />
          </button>

          {/* next button */}
          <button
            className={`mt-4 border border-1 text-white p-3 rounded-full sm:hidden  ${
              index === R.length - 1 ? "bg-gray-100" : ""
            }`}
            onClick={() => {
              if (sessionIndex === 2) {
                setIndex(index + 1);
                setIndexSession(0);
                return;
              }
              setIndexSession(sessionIndex + 1);
            }}
            disabled={index === R.length - 1 && sessionIndex === 2}
          >
            <FaChevronRight size={20} color="black" />
          </button>
        </div>
      </section>
    </div>
  );
};

const FeatureItem = ({ title, icon, description, topic, speakers }) => {
  return (
    <li className="flex w-full flex-1 flex-col lg:items-start">
      <div className="flex items-center  max-sm:mx-4">
        <div className="rounded-full p-4 lg:p-7  max-sm:mx-4 bg-blue-500">
          <img src={icon} alt="map" width={28} height={28} />
        </div>
        <h2 className="bold-20 lg:bold-32 lg:mt-5 capitalize p-3 max-sm:px-4">{topic}</h2>
      </div>
      {speakers.length > 0 ? (
        <h6 className="text-gray-50 lg:w-[700px] max-sm:w-[400px]">
          <ul className="p-2 lg:w-full w-full flex justify-center items-center flex-col">
            {speakers.map((speaker, i) => (
              <div className="p-4 flex items-center border-b border-b-gray-300 w-full">
                <li className=" p-2 font-bold text-sm w-1/2">
                  {i === 0 ? (
                    <span className="text-red-500">
                      {speaker.name} (Moderator)
                    </span>
                  ) : (
                    <span>{speaker.name}</span>
                  )}
                </li>
                <span className="text-sm w-3/4">{speaker.description}</span>
              </div>
            ))}
          </ul>
        </h6>
      ) : (
        <h2 className="bold-20 lg:bold-28 mt-5 capitalize  max-sm:ml-6">{title}</h2>
      )}
      <p className="regular-16 mt-5 bg-white/80 text-gray-30  lg:bg-none">
        {description}
      </p>
    </li>
  );
};

export default Features;

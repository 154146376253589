import { NAV_LINKS } from "../constants";

const Navbar = () => {
  return (
    <nav className="md:flexBetween md:relative md:py-1 md:px-16 px-4">
      <div className="flex gap-12 md:hidden justify-between align-middle items-center">
        <a href="/">
          <img src="/bauLogo.jpg" alt="logo" width={300} height={35} />
        </a>
        <a href="/register" className="hidden regular-16 text-gray-50 flexCenter cursor-pointer pb-1.5 transition-all hover:font-bold hover:border-b-2 hover:border-blue-500">Register</a>
      </div>

      <a href="/" className="lg:flex hidden">
          <img src="/bauLogo.jpg" alt="logo" width={300} height={35} />
        </a>

      <ul className="hidden h-full gap-12 lg:flex">
        {NAV_LINKS.map((link) => (
          <a
            href={link.href}
            key={link.key}
            className="regular-16 text-gray-50 flexCenter cursor-pointer pb-1.5 transition-all hover:font-bold hover:border-b-2 hover:border-blue-500"
          >
            {link.label}
          </a>
        ))}
      </ul>
      {/* 
      <div className="lg:flexCenter hidden">
        <Button 
          type="button"
          title="Login"
          icon="/user.svg"
          variant="btn_dark_green"
        />
      </div> */}

      {/* <Image 
        src="menu.svg"
        alt="menu"
        width={32}
        height={32}
        className="inline-block cursor-pointer lg:hidden"
      /> */}
    </nav>
  );
};

export default Navbar;

import Camp from "./Camp";
import Guide from "./Guide";
import Hero from "./Hero";
import Features from "./Features";

const Home = () => {
  return (
    <>
      <Hero />
      <Camp />
      <Guide />
      <Features />
    </>
  );
};

export default Home;

// NAVIGATION
export const NAV_LINKS = [
  { href: "#home", key: "home", label: "Home" },
  { href: "#program", key: "how_hilink_work", label: "Program" },
  { href: "#speaker", key: "services", label: "Speakers" },
  { href: "#contact", key: "pricing ", label: "Contact" },
  { href: "/register", key: "register ", label: "Register" },
];

// CAMP SECTION
export const PEOPLE_URL = [
  "/person-1.png",
  "/person-2.png",
  "/person-3.png",
  "/person-4.png",
];

// FEATURES SECTION
export const FEATURES = [
  {
    title: "Welcoming & registration",
    icon: "/calendar.svg",
    variant: "green",
    description: "",
    topic: "8:30 - 9:30",
    speakers: [],
  },
  {
    title: "Opening ceremony",
    icon: "/calendar.svg",
    variant: "green",
    description: "",
    topic: "9:30 - 10:15",
    speakers: [],
  },
  {
    title: "State of Lebanon’s Labor Market",
    icon: "/calendar.svg",
    variant: "green",
    description: "",
    topic: "10:15 - 11:30",
    speakers: [
      {
        name: "Mrs Majeda Dagher",
        description:
          "Poet, writer, researcher, journalist, TV & radio presenter",
      },
      {
        name: "H.E Fadi Zouk",
        description:
          "OAM, Consul for Lebanon in the state of Tasmania Australia President of ANLCCI",
      },
      {
        name: "Dr Mohamad Abu Haydar",
        description: "General Director of the Ministry of Economy",
      },
      {
        name: "Mr Nassib Ghobril",
        description:
          "Chief Economist and Head of the Economic Research and Analysis department at Byblos Bank Group",
      },
      {
        name: "Dr. Sami Nader",
        description:
          "Director of Institute of Political Science at Saint Joseph University ",
      },
      {
        name: "Mr. Ali Khalil",
        description:
          "Chairman of the Lebanese Business Council in Kuwait, CEO for Kuwait Financial Center, MARKAZ, Kuwait",
      },
      {
        name: "Mr. Hassan Ezzeldine",
        description: "Chairman of Gray Mackenzie Retail ",
      },
    ],
  },
];
export const FEATURESONE = [
  {
    title: "State of Lebanon’s Labor Market",
    icon: "/calendar.svg",
    variant: "green",
    description: "",
    topic: "10:15 - 11:30",
    speakers: [
      {
        name: "Mrs. Pamela Kousseyfi",
        description:
          "Economic journalist",
      },
      {
        name: "Mrs. Elisasar Naddaf",
        description:
          "Advisor to the Minister of Information ",
      },
      {
        name: "Mrs Sarah Cherif",
        description: "Founding Director of Ruwwad Al Tanmeya (NGO)",
      },
      {
        name: "Mrs. May Makhzoumi",
        description:
          "President of Makhzoumi Foundation",
      },
      {
        name: "Mrs. Carmen Nohra",
        description:
          "Specialist in economic diplomacy and policy advocacy",
      },
      {
        name: "Mrs. Randa Rifai",
        description:
          "Female pioneer, an early adopter, and a distinguished leader in guiding the future of Blockchain technology",
      },
    ],
  },
];
export const FEATURESTWO = [
  {
    title: "State of Lebanon’s Labor Market",
    icon: "/calendar.svg",
    variant: "green",
    description: "",
    topic: "10:15 - 11:30",
    speakers: [
      {
        name: "Dr. Patrick Mardini",
        description:
          "CEO of the Lebanese Institute for Market Studies- LIMS",
      },
      {
        name: "Mr. Moataz Obeid",
        description:
          "CEO of Empowch",
      },
      {
        name: "H.E Mr. Rachid Derbas",
        description: "Lawyer, and EX Minister of social affairs",
      },
      {
        name: "Mr. Fadi Mikati",
        description:
          "Co-Founder & Director of Tripoli Entrepreneurs Club",
      },
      {
        name: "Dr. Rami Baitieh",
        description:
          "CEO at Morrisons UK",
      },
      {
        name: "Mr. Ahmad Zein",
        description:
          "Industrialist in Africa- Executive General Secretary of LIBC",
      },
    ],
  },
];
export const FEATURESTHREE = [
  {
    title: "State of Lebanon’s Labor Market",
    icon: "/calendar.svg",
    variant: "green",
    description: "",
    topic: "10:15 - 11:30",
    speakers: [
      {
        name: "Mr. Nehme Azoury",
        description:
          "Professor, CEO of Exponential",
      },
      {
        name: "Dr. Badih Matar",
        description:
          "Strategy-engineering expert, PWC",
      },
      {
        name: "Dr. Fadi Halout",
        description: "President Trialpha Capital",
      },
      {
        name: "Dr. Claudia Matar",
        description:
          "Lecturer at Balamand University",
      },
      {
        name: "Dr. Jihad Hokayem",
        description:
          "President of Rethinking Lebanon",
      },
      {
        name: "Dr. Nassib Nasr",
        description:
          "Vice President of the board & CEO of Oriental Paper Products (OPP) ",
      },
      {
        name: "Dr. Dal Hitti",
        description:
          "International trainer and Expert in Lebanese Labour market",
      },
    ],
  },
];

export const FEATURES2 = [
  {
    title: "Prof. Mustafa",
    icon: "/calendar.svg",
    variant: "green",
    topic: "11:30 - 12:45",
    speakers: [
      {
        name: "Prof. Jassem Ajaka",
        description:
          "Professor at the Lebanese University",
      },
      {
        name: "R.P Kamel Kamel",
        description:
          "entrepreneur and representative of Maronite Archeparchy of Tripoli",
      },
      {
        name: "Mrs. Madiha Raslan",
        description: "President of the Women Leaders Association",
      },
      {
        name: "Prof. Dr. Fouad Zmokhol",
        description:
          "President of the International Confederation of Lebanese Businesspeople – MIDEL",
      },
      {
        name: "Dr. Hassan Dannawi",
        description:
          "Director at KPMG Saudi Levant Cluster, KSA",
      },
    ],
  },
];

export const FEATURES3 = [
  {
    title: "Prof. Mustafa",
    icon: "/calendar.svg",
    variant: "green",
    topic: "1:30 - 2:45",
    speakers: [
      {
        name: "Mr Mohamad Chamseddine",
        description:
          "Research and writer at Information International Lebanon",
      },
      {
        name: "Dr. Raymond Hayek",
        description:
          "Head of medicine services at Menton Hospital, France",
      },
      {
        name: "President Abbas Fawaz",
        description: "CEO at ITICO Pharma Beirut, and WLCU World President",
      },
      {
        name: "H.E Dr. Manal Abdul Samad",
        description:
          "Expert and senior consultant in tax and financial affairs and local and international government policies",
      },
      {
        name: "Dr. Mounir Rached",
        description:
          "Lecturer at American University of Beirut",
      },
      {
        name: "Mr. Ziad Bekdache",
        description:
          "Vice President of the association of Lebanese industrialists & CEO of Oriental Paper products",
      },
    ],
  },
];

export const FEATUREONE = [
  {
    title: "Prof. Ahmad Shakik",
    icon: "/map.svg",
    variant: "green",
    description:
      "PROFESSOR OF QUANTUM MECHANICS, PARTICLE PHYSICS & ECONOMICS, AT THE LEBANESE UNIVERSITY",
    topic: "Software Eng",
  },
  {
    title: "Dr. Abed Hawli",
    icon: "/calendar.svg",
    variant: "green",
    description:
      "CHIEF ECONOMIST AND HEAD OF THE ECONOMIC RESEARCH & ANALYSIS DEPARTMENT AT THE BYBLOS BANK GROUP",
    topic: "Software Eng",
  },
  {
    title: "Mr. Mohamad Zeid",
    icon: "/tech.svg",
    variant: "green",
    description:
      "PRESIDENT OF THE WORLD ASSOCIATION OF PPP UNITS AND PROFESSIONALS;",
    topic: "Software Eng",
  },
  {
    title: "Dr. Mustafa Khodor",
    icon: "/location.svg",
    variant: "orange",
    description:
      "DIRECTOR OF INSTITUTE OF POLITICAL SCIENCE AT SAINT JOSEPH UNIVERSITY; MANAGING PARTNER AT OAK ASSOCIATES",
    topic: "Software Eng",
  },
];
// FOOTER SECTION
export const FOOTER_LINKS = [
  {
    title: "Learn More",
    links: [
      "About Hilink",
      "Press Releases",
      "Environment",
      "Jobs",
      "Privacy Policy",
      "Contact Us",
    ],
  },
  {
    title: "Our Community",
    links: ["Climbing xixixi", "Hiking hilink", "Hilink kinthill"],
  },
];

export const FOOTER_CONTACT_INFO = {
  title: "Contact Us",
  links: [
    { label: "Admin Officer", value: "+961 3 361480", source: "/user.png" },
    { label: "Email Officer", value: "chirine.kassab@bau.edu.lb", source: "/email.png" },
  ],
};

export const SOCIALS = {
  title: "Social",
  links: [
    {
      link: "/facebook.svg",
      href: "https://www.facebook.com/BeirutArabUniversity?mibextid=dGKdO6",
    },
    {
      link: "/instagram.svg",
      href: "https://www.instagram.com/beirutarabuniversity?igsh=bGFxMTdtMWx2aDQ=",
    },
    {
      link: "/twitter.svg",
      href: "https://x.com/beirutarabuni?s=21&t=kkyAA3chfRL3KZHov_gdYg",
    },
    {
      link: "/youtube.svg",
      href: "https://youtube.com/@BeirutArabUniversity?si=96bR7s7xMEhZixOx",
    },
  ],
};
